import {useContext, useState} from 'react'
import {ApiContext} from '../api-client-provider/ApiClientProvider'
import {RequestConfigType} from '../types/HttpClient.types'

type UsePostReturnType<Response, Params> = [
    <Payload = any>(
        params?: Payload,
        overrideOptions?: RequestConfigType<Params>,
    ) => Promise<Response>,
    boolean,
]

/**
 * Prepares a request to post data to the server
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param path
 * @param options
 * @returns
 */
const usePost = <
    urlsType extends string = string,
    responseType = any,
    paramsType = any,
>(
    path: urlsType,
    options?: RequestConfigType<paramsType>,
): UsePostReturnType<responseType, paramsType> => {
    const {client} = useContext(ApiContext)
    const [loading, setLoading] = useState<boolean>(false)

    const sendRequest = async <payloadType = any>(
        payload?: payloadType,
        overrideOptions?: RequestConfigType<paramsType>,
    ) => {
        try {
            setLoading(true)

            const response = (await client?.doPost<responseType, payloadType>(
                path as string,
                {...options, ...overrideOptions, payload},
            )) as any
            const {data, status} = response
            if (status === 500) {
                throw new Error('Invalid request')
            }
            setLoading(false)
            return data
        } catch (err) {
            setLoading(false)
            throw {
                error: true,
                ...err,
            }
        }
    }

    return [sendRequest, loading]
}

export default usePost
