import Cookies from 'universal-cookie'
import { DriverStorageType } from '../types/session.types'
import CryptoJS from 'crypto-js'

const secret = process.env.REACT_APP_SKEY
const keyName = process.env.REACT_APP_NKEY
class StorageDriver {
    private driver: DriverStorageType

    private cookies: Cookies
    private sessionName: string = 'app-session'

    constructor() {
        this.cookies = new Cookies()
        this.sessionName = keyName || 'app-session'
    }

    public setDriver = (driver: DriverStorageType) => {
        this.driver = driver
    }

    persistData = (data: any) => {
        if (this.driver === 'cookie') {
            if (JSON.stringify(data) === '{}') {
                this.cookies.remove(this.sessionName)
            } else {
                this.cookies.set(this.sessionName, this.obfuscateData(data), {
                    secure: true
                })
            }
        } else {
            localStorage.setItem(this.sessionName, this.obfuscateData(data))
        }
    }

    obfuscateData = (data: any) => {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secret || 'secret').toString()
        return encrypted
    }

    parseData = (data: string | Object | null) => {
        if (typeof data === 'string') {
            const decrypted = CryptoJS.AES.decrypt(data, secret || 'secret').toString(
                CryptoJS.enc.Utf8
            )
            return JSON.parse(decrypted)
        } else {
            return data
        }
    }

    getData = (): any => {
        let data: string | null = ''
        if (this.driver === 'cookie') {
            data = this.cookies.get(this.sessionName)
            return this.parseData(data)
        } else {
            data = localStorage.getItem(this.sessionName)
            return data ? this.parseData(data) : {}
        }
    }
}

export default new StorageDriver()
