import {useContext, useState} from 'react'
import {ApiContext} from '../api-client-provider/ApiClientProvider'
import HttpClient from '../classes/HttpClient'
import {HttpClientMetaType} from '../types/HttpClient.types'
import {
    RequestConfigType,
    DoRequestResponseType,
} from '../types/HttpClient.types'

/**
 * Prepares a get request to be send it by events or controlled actions
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param path
 * @param options
 * @returns
 */
const useGetLazy = <
    UrlsType extends string = string,
    ResponseType = DoRequestResponseType<any>,
    ParamsType = any,
>(
    path: UrlsType,
    options?: {defaultLoading?: boolean} & RequestConfigType<ParamsType>,
): [
    (o?: RequestConfigType<ParamsType>) => Promise<ResponseType>,
    boolean,
    {client?: HttpClient; meta?: HttpClientMetaType},
] => {
    const {client} = useContext(ApiContext)
    const [loading, setLoading] = useState<boolean>(
        options?.defaultLoading || false,
    )
    const [meta, setMeta] = useState<HttpClientMetaType>()

    const sendRequest = async (
        overrideOptions?: RequestConfigType<ParamsType>,
    ) => {
        const {onCompleted} = options || {}
        try {
            setLoading(true)
            const {data, meta: requestMeta} =
                (await client?.doGet<ResponseType>(path as string, {
                    ...options,
                    ...overrideOptions,
                })) as any
            setMeta(requestMeta)
            if (onCompleted) {
                onCompleted({data, meta: requestMeta})
            }
            setLoading(false)
            return data
        } catch (err) {
            setLoading(false)
            return {
                error: true,
                info: err,
            } as DoRequestResponseType
        }
    }

    return [sendRequest, loading, {client, meta}]
}

export default useGetLazy
