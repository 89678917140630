import {useEffect, useRef} from 'react'

/**
 * This hook allows you to capture and compare the current value from previous props
 * and store it as reference for the next render cycle.
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param value
 * @returns
 */
const usePrevProps = <R extends {[key: string]: any}>(value: R): R => {
    const valuesRef = useRef(value)
    useEffect(() => {
        valuesRef.current = value
    })
    return valuesRef.current
}

export default usePrevProps
