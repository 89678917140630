import React from 'react'
import HttpClient from '../classes/HttpClient'
import {ApiProviderType} from '../types/ApiClientProvider.types'
import {HttpClientType} from '../types/HttpClient.types'

export const ApiContext = React.createContext<ApiProviderType>({})
export const ApiContextProvider = ApiContext.Provider
export const ApiContextConsumer = ApiContext.Consumer

interface ApiClientProviderProps {
    children: React.ReactNode
    config: HttpClientType
    strictJSON?: boolean
}

const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
    children,
    config,
    strictJSON,
}) => {
    const client = new HttpClient(config)
    return (
        <ApiContextProvider
            value={{
                client,
                strictJSON,
            }}>
            {children}
        </ApiContextProvider>
    )
}

export default ApiClientProvider
