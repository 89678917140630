import {useContext, useState} from 'react'
import {ApiContext} from '../api-client-provider/ApiClientProvider'
import {RequestConfigType} from '../types/HttpClient.types'

/**
 * Prepares a request to put data on the server
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param path
 * @param options
 * @returns
 */
const usePut = <
    urlsType extends string = string,
    responseType = any,
    paramsType = any,
>(
    path: urlsType,
    options?: RequestConfigType<paramsType>,
) => {
    const {client} = useContext(ApiContext)
    const [loading, setLoading] = useState<boolean>(false)

    const sendRequest = async <payloadType = any>(payload?: payloadType) => {
        try {
            setLoading(true)

            const response = (await client?.doPut<responseType, payloadType>(
                path as string,
                {...options, payload},
            )) as any
            const {data, status} = response
            if (status === 500) {
                throw new Error('Invalid request')
            }
            setLoading(false)
            return data
        } catch (err) {
            setLoading(false)
            return {
                error: true,
                info: err,
            }
        }
    }

    return [sendRequest, loading]
}

export default usePut
